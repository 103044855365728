import React from 'react'
import useEffect from '@hooks/useEffect'
import useSessionStorageState from '@hooks/useSessionStorageState'
import useWebsiteId from '@hooks/useWebsiteId'
import getQueryParam from '@packages/justo-parts/lib/helpers/getQueryParam'

import PageMeta from './PageMeta'
import WebsiteData from './WebsiteData'

export default function Layout(props) {
  const websiteId = useWebsiteId()
  // eslint-disable-next-line
  const [whatsappBusiness, setWhatsappBusiness] = useSessionStorageState('whatsapp-business')
  const setOrderSource = useSessionStorageState('orderSource')[1]

  useEffect(() => {
    const referrer = getQueryParam('referrer')
    if (referrer === 'whatsapp-business') {
      setWhatsappBusiness(true)
      setOrderSource('chat')
    }
  }, [])

  useEffect(() => {
    const order_source = getQueryParam('order_source')
    if (order_source) {
      setOrderSource(order_source)
    }
  }, [])

  const websiteData = <WebsiteData websiteId={websiteId} />

  return (
    <PageMeta>
      {props.children}
      {websiteData}
    </PageMeta>
  )
}
