import logger from '@helpers/logger'
import isServerSide from '@helpers/misc/isServerSide'
import posthog from 'posthog-js'

export const setPosthogUserId = userId => {
  try {
    posthog.identify(userId)
  } catch (e) {
    logger.error(e)
    logger.exception('Posthog not loaded')
  }
}

export const setBasicProperties = properties => {
  if (isServerSide()) return
  properties = {
    ...properties,
    store: window.location.hostname,
    storeUrl: window.location.hostname,
    path: window.location.pathname,
    websiteVersion: 'nextjs'
  }
  posthog.register(properties)
}

export const sendPosthogUserInfo = userInfo => {
  try {
    posthog.people.set(userInfo)
  } catch (e) {
    logger.error(e)
    logger.exception('Posthog not loaded')
  }
}

type Property = any
type Properties = Record<string, Property>

export const sendPosthogData = async (eventType: string, eventProperties: Properties = {}) => {
  try {
    const obj: Properties = JSON.parse(JSON.stringify(eventProperties))
    // Es temporal para debugear
    if (
      eventType === EVENTS.pageView.checkout &&
      eventProperties.checkoutVersion !== 'v3' &&
      eventProperties.checkoutVersion !== 'v2'
    ) {
      const body = JSON.stringify({
        viewer: {},
        message: eventProperties,
        stack: 'Test posthog Event',
        path: 'ab/c'
      })
      await fetch('https://api.getjusto.com/error-stack', {
        method: 'POST',
        body
      })
    }
    posthog.capture(eventType, {...obj})
  } catch (e) {
    console.error({e})
    logger.exception('Posthog not loaded')
  }
}

export const EVENTS = Object.freeze({
  clicked: {
    productChangeAmount: 'clicked-productChangeAmount',
    addressComponentOrder: 'clicked-addressComponentOrder',
    checkoutLogin: 'clicked-checkoutLogin',
    upselling: 'clicked-upselling',
    login: 'clicked-login',
    cartRemoveProduct: 'clicked-cartRemoveProduct',
    cartContinueToCheckout: 'clicked-cartContinueToCheckout',
    cart: 'clicked-cart',
    search: 'clicked-search',
    product: 'clicked-product',
    productAddToCart: 'clicked-productAddToCart',
    help: 'clicked-help',
    cancelOrder: 'clicked-cancel-order',
    changePreferredStore: 'clicked-change-preferred-store',
    omitLogin: 'clicked-omit-login',
    editProduct: 'clicked-edit-product',
    saveEditProduct: 'clicked-save-edit-product',
    giftCheckout: 'clicked-gift-checkout',
    useLocation: 'clicked-use-location',
    addressSubmitButton: 'clicked-address-submit-button',
    addressClear: 'clicked-address-clear-button'
  },
  selected: {
    addressOption: 'selected-address-option'
  },
  results: {
    addressSuggestions: 'results-address-suggestions',
    placeIdIsCovered: 'results-place-id-is-covered'
  },
  error: {
    locationAccessDenied: 'error-location-access-denied',
    locationUnavailable: 'error-location-unavailable'
  },
  blocked: {
    addressSuggestionsEmpty: 'blocked-address-suggestions-empty',
    placeIdNotCovered: 'blocked-place-id-not-covered',
    storeClosed: 'blocked-store-closed'
  },
  dragged: {
    addressPin: 'dragged-address-pin'
  },
  product: {
    setQuantityTo0: 'update-quantity-0-product'
  },
  pageView: {
    checkout: 'pageView-checkout',
    order: 'pageView-order',
    home: 'pageView-home',
    tracking: 'pageView-tracking',
    publication: 'pageView-publication'
  },
  coupon: {
    apply: 'coupon-apply',
    failed: 'coupon-failed'
  },
  address: {
    failed: 'address-failed',
    accept: 'address-accept'
  },
  pay: {
    passValidations: 'pay-passValidations',
    failed: 'pay-failed',
    successful: 'pay-successful',
    successfulTest: 'pay-successful-test'
  },
  login: 'login',
  startSession: 'start-session',
  auth: {
    startsAuthModal: 'auth-modal-starts',
    authModalEndsSuccess: 'auth-modal-ends-success',
    authModalEndsNoAuth: 'auth-modal-ends-no-auth'
  }
})
