import {InMemoryCache} from '@apollo/client/cache'
import {WRAPPED_WEBSITE_ID_KEY} from '@components/WrappedWebsite/constants'
import getDeviceId from '@helpers/auth/getDeviceId'
import getFingerprint from '@helpers/auth/getFingerprint'
import isServerSide from '@helpers/misc/isServerSide'
import getDomain from '@helpers/websites/getDomain'
import {localStorageGetItem} from '@hooks/useLocalStorageState'
import {getSavedValue} from '@hooks/useSessionStorageState'
import {createClient, OrionApolloClient} from '@orion-js/graphql-client'
import {NormalizedCacheObject} from 'apollo-cache-inmemory'

import refreshJWT from '../refreshJWT'

import getTimezone from './getTimezone'

declare const window: {
  __NEXT_DATA__: {
    apolloState: NormalizedCacheObject
  }
}

const windowApolloState = !isServerSide() && window.__NEXT_DATA__.apolloState

export default function createApolloClient({
  endpointURL,
  useSubscriptions
}): OrionApolloClient<any> {
  // use unique session key per project to avoid
  // loging in and out every time you change it
  // in localhost
  const cache = new InMemoryCache({
    dataIdFromObject: function (object) {
      if ((object as any)._id) return (object as any)._id
      if (object.id) return object.id
      if (object.__typename === 'Cart') return 'cart'
    },
    typePolicies: {
      // https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
      Schedule: {
        merge: true
      },
      Country: {
        merge: true
      },
      WebsiteDesign: {
        merge: true
      },
      ProductAvailability: {
        merge: true
      },
      StoreAddress: {
        merge: true
      },
      MenuSectionConfiguration: {
        merge: true
      },
      Cart: {
        merge: true
      },
      Preference: {
        fields: {
          cart: {
            merge: true
          }
        }
      },
      CouponStatus: {
        merge: true
      }
    }
  }).restore(windowApolloState || {})

  return createClient({
    ssrMode: isServerSide(),
    endpointURL,
    useSubscriptions,
    cache,
    batch: false,
    apolloOverrides: {
      // Disable the Apollo cache on the server-side
      // Othwerwise, RAM usage will keep growing on production as the server stores more and more website data.
      defaultOptions: isServerSide()
        ? {
            watchQuery: {
              fetchPolicy: 'no-cache'
            },
            query: {
              fetchPolicy: 'no-cache'
            }
          }
        : {}
    },
    resolvers: {},
    getHeaders(body) {
      if (isServerSide()) return {}
      const timezone = getTimezone() // TODO: review headers on the server side
      const storedWebsiteId = getSavedValue(WRAPPED_WEBSITE_ID_KEY)

      return {
        'X-ORION-DEVICEID': getDeviceId(),
        'X-ORION-FP': getFingerprint(),
        'X-ORION-DOMAIN': getDomain() || '',
        'X-ORION-REFERRER': document.referrer,
        'X-ORION-TIMEZONE': timezone,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type '{ __NE... Remove this comment to see the full error message
        'X-ORION-PATHNAME': window.location.pathname,
        ...(storedWebsiteId ? {'X-ORION-WRAPPED-WEBSITE': storedWebsiteId} : {})
      }
    },
    saveSession(session) {},
    getSession(session) {
      return {}
    },
    getJWT: () => (isServerSide() ? null : localStorageGetItem('justo_jwt_token') || null),
    refreshJWT
  })
}
