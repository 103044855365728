import React, {lazy} from 'react'
import useInitialData from '@page-components/Layout/useInitialData'

export default function GoogleTagManagerWrapper() {
  const {integrations: config} = useInitialData()

  if (!config?.integrations?.tagManagerId) return null

  const GoogleTagManager = lazy(() => import('./GoogleTagManager'))

  return <GoogleTagManager />
}
