import React, {useCallback, useEffect, useState} from 'react'
import SafeSuspense from '@components/SafeSuspense'
import AddressesContext, {AddressesState} from '@helpers/addresses/AddressesContext'
import {
  Address,
  AddressesConfig,
  ClearType,
  DeliveryTypes,
  Location,
  Store
} from '@helpers/addresses/models'
import getDeviceId from '@helpers/auth/getDeviceId'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import useCountryCode from '@hooks/useCountryCode'
import useDebounce from '@hooks/useDebounce'
import useMessage from '@hooks/useMessage'
import useShouldUseNewAddressesUI from '@hooks/useShouldUseNewAddressesUI'
import useUserId from '@hooks/useUserId'
import useWebsiteId from '@hooks/useWebsiteId'
import {useClient} from 'apollo-hooks'
import usePrevious from 'use-previous'
import {v4 as uuidv4} from 'uuid'

import useAddressOptions from './hooks/useAddressOptions'
import {
  createAddressMutation,
  getGeocodePlaceQuery,
  getPreferencesQuery,
  getStoresInCoverageQuery,
  setAddressIdMutation,
  setDeliveryTypeMutation,
  setPlaceIdMutation,
  setPreferredStoreIdMutation,
  setStoreIdMutation,
  updateAddressMutation
} from './queries'

interface AddressesProviderProps extends AddressesConfig {
  children: React.ReactNode
  initialState: Partial<AddressesState>
}

interface InitializeParams {
  client: any
  countryCode: string
  websiteId: string
  userId?: string
  deviceId: string
  getAddressOptions?: boolean
}

interface SetPreferencesParams {
  client: any
  websiteId: string
  placeId?: string
  addressId?: string
  location?: Location
}

interface GetStoresInCoverageParams {
  client: any
  websiteId: string
  placeId?: string
}

function AddressesProviderInner({
  initialState,
  isSingleWebsite,
  client,
  userId,
  children
}: AddressesProviderProps) {
  const websiteId = useWebsiteId()
  const sessionId = uuidv4()

  const [addressId, setAddressId] = useState<string | undefined>(
    initialState?.addressId ?? undefined
  )

  const [placeId, setPlaceId] = useState<string | undefined>(undefined)

  const [location, setLocation] = useState<Location | undefined>(
    initialState?.address?.location ?? undefined
  )
  const [storeId, setStoreId] = useState<string | undefined>(undefined)
  const previousStoreId = usePrevious(storeId)
  const [preferredDeliveryStoreId, setPreferredDeliveryStoreIdState] = useState<string | undefined>(
    undefined
  )
  const previousPreferredDeliveryStoreId = usePrevious(preferredDeliveryStoreId)

  const [address, setAddress] = useState<Address | undefined>(undefined)
  const [storesInCoverage, setStoresInCoverage] = useState<Store[] | undefined>(undefined)
  const [addressLine2, setAddressLine2] = useState<string | undefined>(undefined)
  const previousAddressLine2 = usePrevious(addressLine2)
  const [deliveryType, setDeliveryType] = useState<DeliveryTypes>(DeliveryTypes.DELIVERY)
  const previousDeliveryType = usePrevious(deliveryType)
  const [loadingGeolocation, setLoadingGeolocation] = useState<boolean>(false)
  const [sessionTokenGoogleMaps, setSessionTokenGoogleMaps] = useState<string>(uuidv4())
  // we just want to initialize once
  const [isInitialized, setIsInitialized] = useState<boolean>(false)

  const [debouncedAddressLine2] = useDebounce(addressLine2, 500)
  const previousDebouncedAddressLine2 = usePrevious(debouncedAddressLine2)
  const previousUserId = usePrevious(userId)
  const showMessage = useMessage()

  const deviceId = getDeviceId()
  const countryCode = useCountryCode()

  const {filter, setFilter, options, selectedCity, setSelectedCity, isLoadingOptions} =
    useAddressOptions({
      client,
      websiteId,
      userId,
      deviceId,
      address,
      sessionTokenGoogleMaps
    })

  const initialize = useCallback(
    async ({client, websiteId, userId, deviceId}: InitializeParams) => {
      // get setup
      const {data: preferencesData} = await client.query({
        query: getPreferencesQuery,
        variables: {websiteId}
      })
      if (preferencesData?.preferences) {
        const preferences = preferencesData.preferences
        setAddressId(preferences.addressId)
        setAddress(preferences.address)
        setLocation(preferences.address?.location)
        setStoreId(preferences.storeId)
        setPlaceId(preferences.placeId)
        setAddressLine2(preferences.address?.addressLine2)
        setPreferredDeliveryStoreIdState(preferences.preferredDeliveryStoreId)
        setDeliveryType(preferences.deliveryType)
        setIsInitialized(true)
        if (preferences.placeId && preferences.deliveryType === DeliveryTypes.DELIVERY) {
          getStoresInCoverage({client, placeId: preferences.placeId, websiteId})
        }
      }
    },
    []
  )

  const getStoresInCoverage = useCallback(
    async ({client, websiteId, placeId}: GetStoresInCoverageParams) => {
      if (!websiteId || !placeId) return null
      const {data} = await client.query({
        query: getStoresInCoverageQuery,
        variables: {
          websiteId,
          placeId
        }
      })
      if (data?.storesInCoverage) {
        const stores = data.storesInCoverage
        setStoresInCoverage(stores)
        if (stores?.length) setStoreId(stores[0]._id)
        else setStoreId(undefined)
      }
    },
    []
  )

  const createAddress = useCallback(
    async ({addressLine2}: {addressLine2: string}) => {
      const {data} = await client.mutate({
        mutation: createAddressMutation,
        variables: {placeId, location, addressLine2, sessionTokenGoogleMaps}
      })
      if (data.createAddress) {
        // await getAddresses({client, websiteId, userId, deviceId})
        const address = data.createAddress
        setAddressId(address._id)
        setAddress(address)
        setPlaceId(address.placeId)
        setLocation(address.location)
        if (deliveryType === DeliveryTypes.DELIVERY) {
          getStoresInCoverage({client, websiteId, placeId})
        }
        setSessionTokenGoogleMaps(uuidv4()) // https://developers.google.com/maps/documentation/places/web-service/session-tokens
      }
    },
    [
      client,
      placeId,
      location,
      sessionTokenGoogleMaps,
      deliveryType,
      getStoresInCoverage,
      websiteId
    ]
  )

  const setPreferences = useCallback(
    async ({
      client,
      websiteId,
      addressId = undefined,
      placeId = undefined,
      location = undefined
    }: SetPreferencesParams) => {
      if (addressId) {
        // addressId
        const {data} = await client.mutate({
          mutation: setAddressIdMutation,
          variables: {websiteId, addressId}
        })
        if (data?.setUserPreferences) {
          const preferences = data.setUserPreferences
          setAddressId(addressId)
          setAddress(preferences.address)
          setPlaceId(preferences.placeId)
          setLocation(preferences.address.location)
          if (deliveryType === DeliveryTypes.DELIVERY) {
            getStoresInCoverage({client, websiteId, placeId: preferences.placeId})
          }
        }
      } else if (placeId) {
        // placeId
        try {
          const {data} = await client.mutate({
            mutation: setPlaceIdMutation,
            variables: {websiteId, placeId, sessionTokenGoogleMaps},
            refetchQueries: ['getInitialAddressData']
          })
          if (data?.setPlaceId) {
            const preferences = data.setPlaceId
            if (preferences.storeId) {
              sendPosthogData(EVENTS.results.placeIdIsCovered, {
                storeId: preferences.storeId
              })
            } else {
              sendPosthogData(EVENTS.blocked.placeIdNotCovered, {
                placeId
              })
            }
            if (preferences?.store?.schedule?.itsOpenNow === false) {
              sendPosthogData(EVENTS.blocked.storeClosed, {
                storeId: preferences.storeId
              })
            }
            setPlaceId(placeId)
            setStoreId(preferences.storeId)
            setAddress(preferences.address)
            setLocation(preferences.address?.location)
            if (placeId && deliveryType === DeliveryTypes.DELIVERY) {
              getStoresInCoverage({client, websiteId, placeId})
            }
            const newAddressLine2 = preferences.address?.addressLine2
            if (newAddressLine2) setAddressLine2(newAddressLine2)
          }
        } catch (e: any) {
          const placeIdError = e?.graphQLErrors?.[0]?.validationErrors?.placeId
          if (e?.message === 'Validation Error' && placeIdError) {
            showMessage(new Error(placeIdError))
          }
        }
        setSessionTokenGoogleMaps(uuidv4()) // https://developers.google.com/maps/documentation/places/web-service/session-tokens
      } else if (location) {
        // get location
        setLoadingGeolocation(true)
        const {data} = await client.query({
          query: getGeocodePlaceQuery,
          variables: {location}
        })
        if (data?.place) {
          const newPlace = data.place
          sendPosthogData(EVENTS.dragged.addressPin, {
            location
          })
          await setPreferences({client, websiteId, placeId: newPlace._id})
        }
        setLoadingGeolocation(false)
      }
    },
    [deliveryType, getStoresInCoverage, sessionTokenGoogleMaps]
  )

  const clear = useCallback(
    (type: ClearType) => {
      if (type === ClearType.SELECTED_CITY || type === ClearType.PLACE_ID) {
        client.mutate({
          mutation: setPlaceIdMutation,
          variables: {websiteId, placeId: null},
          refetchQueries: ['getInitialAddressData']
        })

        sendPosthogData(EVENTS.clicked.addressClear, {
          placeId
        })
        setPlaceId(undefined)
        setSelectedCity(undefined)
        setLocation(undefined)
        setAddressLine2(undefined)
      }
    },
    [client, placeId, setSelectedCity, websiteId]
  )

  const setPreferredDeliveryStoreId = useCallback(
    (preferredDeliveryStoreId: string) => {
      if (preferredDeliveryStoreId) {
        const isStoreInCoverage = Boolean(
          storesInCoverage?.find(store => store._id === preferredDeliveryStoreId)
        )
        if (isStoreInCoverage) setPreferredDeliveryStoreIdState(preferredDeliveryStoreId)
      }
    },
    [storesInCoverage]
  )

  // initialize
  useEffect(() => {
    if (!isInitialized && client && websiteId) {
      initialize({
        client,
        countryCode,
        websiteId,
        userId,
        deviceId
      })
    }
    if (userId !== previousUserId) {
      initialize({
        client,
        countryCode,
        websiteId,
        userId,
        deviceId
      })
    }
  }, [isInitialized, client, websiteId, userId, deviceId, initialize, countryCode, previousUserId])

  // changes preferredDeliveryStoreId
  useEffect(() => {
    // set preferred store id
    if (previousPreferredDeliveryStoreId !== preferredDeliveryStoreId && preferredDeliveryStoreId) {
      const setPreferredStoreId = async () => {
        client.mutate({
          mutation: setPreferredStoreIdMutation,
          variables: {websiteId, preferredDeliveryStoreId: preferredDeliveryStoreId}
        })
      }
      setPreferredStoreId()
    }
  }, [client, previousPreferredDeliveryStoreId, preferredDeliveryStoreId, websiteId])

  // changes storeId
  useEffect(() => {
    if (storeId && websiteId && previousStoreId !== storeId) {
      const setStoreIdFn = async () => {
        client.mutate({
          mutation: setStoreIdMutation,
          variables: {websiteId, storeId}
        })
      }
      setStoreIdFn()
    }
  }, [client, deliveryType, placeId, previousStoreId, storeId, websiteId])

  // changes addressLine2
  useEffect(() => {
    // Hay que hacer que se le asigne al address que se acaba de crear
    if (
      placeId &&
      websiteId &&
      address?._id &&
      addressLine2 &&
      previousDebouncedAddressLine2 !== debouncedAddressLine2
    ) {
      const setAddressLine2Fn = async () => {
        client.mutate({
          mutation: updateAddressMutation,
          variables: {addressId: address._id, address: {addressLine2, placeId}}
        })
      }
      setAddressLine2Fn()
    }
  }, [
    address?._id,
    addressLine2,
    client,
    debouncedAddressLine2,
    placeId,
    previousAddressLine2,
    previousDebouncedAddressLine2,
    websiteId
  ])

  // change deliveryType
  useEffect(() => {
    // set preferred store id
    if (isInitialized && websiteId && deliveryType && previousDeliveryType !== deliveryType) {
      const setDeliveryTypeFn = async () => {
        client.mutate({
          mutation: setDeliveryTypeMutation,
          variables: {websiteId, deliveryType}
        })
        if (placeId && deliveryType === DeliveryTypes.DELIVERY) {
          getStoresInCoverage({client, websiteId, placeId})
        }
      }
      setDeliveryTypeFn()
    }
  }, [
    client,
    previousDeliveryType,
    deliveryType,
    websiteId,
    isInitialized,
    getStoresInCoverage,
    placeId
  ])

  return (
    <>
      <AddressesContext.Provider
        value={{
          sessionId,

          // addresses,
          address,
          addressId,
          filter,
          options,
          // suggestedPlaces,
          placeId,
          location,
          preferredDeliveryStoreId,
          storesInCoverage,
          isLoadingOptions,
          addressLine2,
          selectedCity,

          deliveryType,
          setDeliveryType,
          storeId,
          setStoreId,
          loadingGeolocation,
          setLoadingGeolocation,

          setFilter,
          setPreferredDeliveryStoreId,
          setAddressId: (addressId: string) => setPreferences({client, websiteId, addressId}),
          setSelectedCity,
          setPlaceId: (placeId: string) => setPreferences({client, websiteId, placeId}),
          setLocation: (location: Location) => setPreferences({client, websiteId, location}),
          setAddressLine2,
          createAddress,

          clear
        }}>
        {children}
      </AddressesContext.Provider>
    </>
  )
}

const SuspendableAddressesProvider = ({children}: {children: React.ReactNode}): any => {
  const client = useClient()
  const userId = useUserId()
  const websiteId = useWebsiteId()
  const shouldUseNewAddressesUI = useShouldUseNewAddressesUI()

  if (!shouldUseNewAddressesUI) return children
  return (
    <AddressesProviderInner
      client={client}
      initialState={{}}
      isSingleWebsite={true}
      websiteId={websiteId}
      userId={userId}>
      {children}
    </AddressesProviderInner>
  )
}

export default function AddressesProvider({children}: {children: React.ReactNode}) {
  return (
    <SafeSuspense fallback={() => <>{children}</>}>
      <SuspendableAddressesProvider>{children}</SuspendableAddressesProvider>
    </SafeSuspense>
  )
}
