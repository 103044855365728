import numeral from 'numeral'

import standardLocale from './standardLocale'

const localeMap = {
  es_CL: () =>
    registerLocale('es_CL', {
      ...standardLocale,
      delimiters: {
        thousands: '.',
        decimal: ','
      },
      currency: {
        symbol: '$'
      }
    }),

  es_PE: () =>
    registerLocale('es_PE', {
      ...standardLocale,
      delimiters: {
        thousands: ',',
        decimal: '.'
      },
      currency: {
        symbol: 'S/ '
      }
    }),

  es_MX: () =>
    registerLocale('es_MX', {
      ...standardLocale,

      delimiters: {
        thousands: ',',
        decimal: '.'
      },
      currency: {
        symbol: '$'
      }
    }),

  es_CO: () =>
    registerLocale('es_CO', {
      ...standardLocale,

      delimiters: {
        thousands: '.',
        decimal: ','
      },
      currency: {
        symbol: '$'
      }
    }),

  en_US: () =>
    registerLocale('en_US', {
      ...standardLocale,
      delimiters: {
        thousands: ',',
        decimal: '.'
      },
      currency: {
        symbol: '$'
      }
    }),

  es_US: () =>
    registerLocale('es_US', {
      ...standardLocale,
      delimiters: {
        thousands: ',',
        decimal: '.'
      },
      currency: {
        symbol: '$'
      }
    }),

  es_EC: () =>
    registerLocale('es_EC', {
      ...standardLocale,
      delimiters: {
        thousands: ',',
        decimal: '.'
      },
      currency: {
        symbol: '$'
      }
    }),

  es_CR: () =>
    registerLocale('es_CR', {
      ...standardLocale,

      delimiters: {
        thousands: ',',
        decimal: '.'
      },
      currency: {
        symbol: '₡'
      }
    })
}

const registerLocale = (locale: string, opts: any) => {
  if (numeral.locales[locale.toLowerCase()] === undefined) {
    numeral.register('locale', locale, opts)
  }
}

export default function registerLocales(only: string[] = []) {
  let locales = Object.keys(localeMap)
  if (only?.length > 0) {
    locales = locales.filter(locale => only.includes(locale))
  }

  locales.forEach(locale => {
    localeMap[locale]()
  })
}
