import {ApolloClient} from '@apollo/client'

// to use in the rendered UI
export enum AddressFormType {
  Input,
  DeliveryTypes,
  Form,
  FormWithTopMap,
  FormWithBottomMap,
  FormAndMap
}

export interface AddressesConfig {
  isSingleWebsite: boolean
  websiteId?: string
  userId?: string
  deviceId?: string

  client: ApolloClient<any>
}

export interface Location {
  lat: number
  lng: number
}

export enum DeliveryTypes {
  GO = 'go',
  DELIVERY = 'delivery',
  SERVE = 'serve'
}

export interface Place {
  _id: string
  addressId?: string
  text: string
  secondaryText: string
  location: Location
}

export interface Option {
  label: string
  value: string | number
}

export interface Address {
  _id: string
  address: string
  placeId: string
  addressSecondary: string
  addressLine2?: string
  location: Location
}

export interface CustomStore {
  label: string
  value: string
  acceptServe: boolean
  acceptGo: boolean
  schedulingOrdersDays: number
  acceptServeSchedulingOrders: boolean
  address?: {
    placeId: string
    location: Location
  }
}

export interface Store {
  _id: string
  name: string
  address?: {
    location: Location
  }
  acceptServeSchedulingOrders: boolean
}

export enum ClearType {
  SELECTED_CITY,
  PLACE_ID
}
