import {lazy} from 'react'
import {websiteInitialData_cached_website} from '@data/queries/website/__generated__/websiteInitialData_cached'
import doesCountryRequireCookies from '@helpers/websites/doesCountryRequireCookies'

export default function CookiesBanner({website}: {website: websiteInitialData_cached_website}) {
  if (doesCountryRequireCookies(website.countryCode) === false) {
    return null
  }

  const CookiesBannerComp = lazy(() => import('@components/CookiesBanner'))
  return <CookiesBannerComp website={website} />
}
