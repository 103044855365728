/* eslint-disable no-restricted-imports */
import React from 'react'
import NextImage, {ImageProps} from 'next/image'

export default function Image(props: ImageProps) {
  // We are disabling image optimization for now
  if (!props.src) {
    return null
  }
  return <NextImage {...props} unoptimized={true} />
}
