import gql from 'graphql-tag'

import cartItemFragment from './cartItemFragment.main'

export default gql`
  fragment cartFragment on Preference {
    cart {
      items {
        _id
        isOutOfStock
        amount
        ...resumeCartItem
      }
      bagAmount
      storeId
      deliveryType
      deliveryFee
      menuId
      itemsPrice
      realPrice: itemsPrice(includeDiscount: false)
      totalPrice
      amountToPay
      calculatedBagsRequired
      maxBagsAllowed
      benefits
    }
  }

  ${cartItemFragment}
`
