import React, {lazy} from 'react'
import useInitialData from '@page-components/Layout/useInitialData'

export default function GoogleAnalytics() {
  const {integrations: config} = useInitialData()

  if (!config?.integrations?.googleAnalyticsId && !config?.integrations?.googleAnalyticsV4Id) {
    return null
  }

  const GA = lazy(() => import('./GA'))

  return <GA />
}
