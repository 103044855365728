import isServerSide from '@helpers/misc/isServerSide'
import {localStorageGetItem} from '@hooks/useLocalStorageState'
import authURL from '@providers/url'

import saveJWT from './saveJWT'

export default async function refreshJWT() {
  if (isServerSide()) return

  if (!localStorageGetItem('justo_jwt_refresh')) {
    console.log('removing session because no refresh token')
    await saveJWT({})
    return
  }

  const response = await fetch(`${authURL}/auth/refreshToken`, {
    method: 'POST',
    headers: {
      'X-ORION-REFRESH': localStorageGetItem('justo_jwt_refresh') || null
    }
  })

  const result = await response.json()

  if (result.error || !result.token) {
    console.log('Error refreshing JWT. Will reset Session')
    await saveJWT({})
    return
  }

  await saveJWT({token: result.token})
}
