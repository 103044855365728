import {OrionApolloClient} from '@orion-js/graphql-client'
import getEnv from '@providers/getEnv'
import services from '@providers/services'

import createApolloClient from './createApolloClient'

const env = getEnv()
const clients: {[key: string]: OrionApolloClient<any>} = {}

export default function getClients() {
  if (Object.keys(clients).length > 0) return clients

  for (const key in services) {
    const service = services[key]
    const {urls} = service
    const url = urls[env]
    clients[key] = createApolloClient({
      endpointURL: url,
      useSubscriptions: service.useSubscriptions
    })
  }

  return clients
}
