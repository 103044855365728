import {EVENTS, sendPosthogData} from '@helpers/posthog'
import useWebsiteId from '@hooks/useWebsiteId'
import getJWTSavedData from '@providers/apollo/getJWTSavedData'
import onSessionSaved from '@providers/apollo/onSessionSaved'
import getEnv from '@providers/getEnv'
import isEmpty from 'lodash/isEmpty'
import {useRouter} from 'next/router'

const getBaseURL = (websiteId: string, returnPath: string, fallbackURL?: string) => {
  const baseURL = {
    local: 'http://auth.internal:5140',
    dev: 'https://auth.bejusto.com',
    prod: 'https://auth.getjusto.com'
  }[getEnv()]
  const domain = encodeURIComponent(window?.location?.host)
  const e = encodeURIComponent
  const params = {
    domain,
    return: returnPath,
    fallback: fallbackURL
  }
  const url = `${baseURL}/${websiteId}?${Object.entries(params)
    .map(([k, v]) => (v ? `${e(k)}=${e(v)}` : null))
    .filter(Boolean)
    .join('&')}`
  return url
}

export function useAuthLoginURL(options?: {fallbackURL?: string}) {
  const websiteId = useWebsiteId()
  const router = useRouter()
  return getBaseURL(websiteId, router.asPath, options?.fallbackURL)
}

const popupCenter = (url: string, title: string, w: number, h: number) => {
  // Fixes dual-screen position                             Most browsers      Firefox
  const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX
  const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height

  const systemZoom = width / window.screen.availWidth
  const left = (width - w) / 2 / systemZoom + dualScreenLeft
  const top = (height - h) / 2 / systemZoom + dualScreenTop
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  )

  if (window.focus) newWindow.focus()
  return newWindow
}

export function useLoginViaAuthWindow() {
  const websiteId = useWebsiteId()
  const router = useRouter()

  return () => {
    const url = getBaseURL(
      websiteId,
      '/auth/token/ready?return=' + encodeURIComponent(router.asPath)
    )
    sendPosthogData(EVENTS.auth.startsAuthModal)
    // open url as a login dialog
    const loginWindow = popupCenter(url, 'Login', 350, 450)
    // listen for window closed
    const interval = setInterval(async () => {
      if (loginWindow.closed) {
        clearInterval(interval)
        const tokenData = getJWTSavedData()
        if (isEmpty(tokenData)) {
          sendPosthogData(EVENTS.auth.authModalEndsNoAuth)
          // user closed the window without logging in
        } else {
          sendPosthogData(EVENTS.auth.authModalEndsSuccess)
          await onSessionSaved(tokenData)
        }
      }
    }, 50)
  }
}
