import React, {lazy} from 'react'
import useInitialData from '@page-components/Layout/useInitialData'

export default function LogRocket() {
  const {integrations: config} = useInitialData()

  if (!config?.integrations?.logRocketId) return null

  const LogRocketIntegration = lazy(() => import('./LogRocketIntegration'))

  return <LogRocketIntegration />
}
