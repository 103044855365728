import React from 'react'
import ClientSuspense from '@components/ClientSuspense'
import LayoutComponent from '@components/Layout'
import SafeSuspense from '@components/SafeSuspense'
import {websiteInitialData_cached} from '@data/queries/website/__generated__/websiteInitialData_cached'
import getContrastColor from '@helpers/misc/getContrastColor'
import CookiesBanner from '@page-components/CookiesBanner'
import AddressesProvider from '@providers/Addresses'
import LayoutColorThemeContext from '@providers/LayoutColorThemeContext'
import WebsiteIdContext from '@providers/WebsiteIdContext'
import dynamic from 'next/dynamic'

import InitialDataContext from './InitialDataContext'
import {WebsiteProps} from './Website'

const NoWebsite = dynamic(() => import('@page-components/NoWebsite'))

export interface LayoutProps {
  initialData: websiteInitialData_cached
  children: React.ReactNode
  forceLightTheme?: boolean
  darkTheme?: 'dark' | 'darkv2'
  LayoutComponent: React.ComponentType<WebsiteProps>
}

export default function AbstractLayout(props: LayoutProps) {
  const {
    initialData,
    forceLightTheme = false,
    LayoutComponent: LayoutImplementation,
    darkTheme = 'dark'
  } = props

  if (!initialData) {
    return <NoWebsite />
  }

  const isDarkBackground =
    getContrastColor(initialData.design?.design?.backgroundColor || '#ffffff') === '#ffffff'

  return (
    <SafeSuspense
      fallback={
        <div className="initialLoadingContainer">
          <div className="initialLoadingItem" />
        </div>
      }>
      <InitialDataContext.Provider value={initialData}>
        <WebsiteIdContext.Provider value={initialData.website._id}>
          <LayoutColorThemeContext.Provider
            value={forceLightTheme ? 'lightOverwritten' : isDarkBackground ? darkTheme : 'light'}>
            <AddressesProvider>
              {/* 
                  Note: We are wrapping AddressesProvider between suspenses as the AddressesProvider 
                  returns its children immediately  when capturing a suspense trigger. We do this to
                  avoid request errors inside the AddressProvider to block deeper rendering.
              */}
              <SafeSuspense
                fallback={
                  <div className="initialLoadingContainer">
                    <div className="initialLoadingItem" />
                  </div>
                }>
                <LayoutComponent>
                  <LayoutImplementation website={initialData?.website}>
                    {props.children}
                  </LayoutImplementation>
                </LayoutComponent>
              </SafeSuspense>
            </AddressesProvider>
          </LayoutColorThemeContext.Provider>
        </WebsiteIdContext.Provider>
      </InitialDataContext.Provider>
      <ClientSuspense fallback={null}>
        <CookiesBanner website={initialData.website} />
      </ClientSuspense>
    </SafeSuspense>
  )
}
