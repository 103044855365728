import {useEffect, useMemo, useState} from 'react'
import {ApolloClient, useQuery} from '@apollo/client'
import {Address, Option, Place} from '@helpers/addresses/models'
import {EVENTS, sendPosthogData} from '@helpers/posthog'
import useDebounce from '@hooks/useDebounce'
import uniqBy from 'lodash/uniqBy'
import usePrevious from 'use-previous'

import {searchPlacesQuery} from '../queries'

const mapAddressToPlace = (address?: Address & Place): Place | null => {
  if (!address) return null
  return {
    _id: address.placeId ?? address._id,
    text: address.address ?? address.text,
    secondaryText: address.addressSecondary ?? address.secondaryText,
    location: address.location
  }
}

const mapPlaceToOption = (place: Place): Option | null => {
  if (!place) return null
  return {
    label: `${place.text}, ${place.secondaryText}`,
    value: place._id
  }
}

const useAddressOptions = ({
  client,
  websiteId,
  userId,
  deviceId,
  address,
  sessionTokenGoogleMaps
}: {
  client: ApolloClient<any>
  websiteId: string
  userId?: string
  deviceId: string
  address?: Address
  sessionTokenGoogleMaps: string
}) => {
  const previousUserId = usePrevious(userId)
  const [filter, setFilter] = useState<string | undefined>(undefined)
  const [debouncedFilter] = useDebounce(filter, 500)
  const [selectedCity, setSelectedCity] = useState<string | undefined>(undefined)

  const {
    data: dataAddresses,
    loading: loadingAddresses,
    refetch: refetchAddresses
  } = useQuery(searchPlacesQuery, {
    variables: {
      filter: '',
      websiteId,
      userId,
      countryCodes: [],
      unrestrictedSearch: true
      // We intentionally left the sessionToken out because it's not needed and it'd trigger this query every time that the session changes
    },
    fetchPolicy: 'network-only',
    skip: !websiteId
  })
  const {addresses: queryAddresses} = dataAddresses || {}

  const {data: dataSuggestions, loading: loadingSuggestions} = useQuery(searchPlacesQuery, {
    variables: {
      filter: `${debouncedFilter}${selectedCity ? `, ${selectedCity}` : ''}`,
      websiteId,
      userId,
      countryCodes: [],
      sessionTokenGoogleMaps,
      unrestrictedSearch: true
    },
    onCompleted: data => {
      const suggestionAmount = Array.isArray(data?.addresses) && data?.addresses?.length
      const filter = `${debouncedFilter}${selectedCity ? `, ${selectedCity}` : ''}`
      const isLongFilter = debouncedFilter && debouncedFilter.length >= 6

      if (isLongFilter && suggestionAmount) {
        return sendPosthogData(EVENTS.results.addressSuggestions, {suggestionAmount, filter})
      }
      if (isLongFilter) {
        return sendPosthogData(EVENTS.blocked.addressSuggestionsEmpty, {
          filter
        })
      }
    },
    skip: !websiteId || !debouncedFilter
  })
  const {addresses: suggestions} = dataSuggestions || {}

  useEffect(() => {
    // If the user just logged in, load it's addresses
    if (!previousUserId && userId) {
      refetchAddresses()
    }
  }, [refetchAddresses, client, deviceId, previousUserId, userId, websiteId])

  const addressOptions = useMemo(() => {
    const addresses = queryAddresses?.map((address: Address) => ({
      ...address,
      placeId: address._id
    }))

    if (!addresses?.length && !address) return []

    const placeFromAddress = mapAddressToPlace(address as Address & Place)
    const allOptions = placeFromAddress ? [placeFromAddress, ...(addresses ?? [])] : addresses
    return allOptions.map(mapPlaceToOption)
  }, [address, queryAddresses])

  const suggestedOptions = useMemo(() => {
    const suggestedPlaces = suggestions
    if (!suggestedPlaces?.length) return []
    return suggestedPlaces.map(mapPlaceToOption)
  }, [suggestions])

  const options = useMemo(() => {
    const filteredAddressOptions = addressOptions.filter((option: Option) =>
      !filter ? true : option.label.toLowerCase().includes(filter.toLowerCase())
    )

    return uniqBy([...filteredAddressOptions, ...suggestedOptions], 'value')
  }, [addressOptions, suggestedOptions, filter])

  return {
    options,
    isLoadingOptions: loadingAddresses || loadingSuggestions,
    selectedCity,
    setSelectedCity,
    filter,
    setFilter
  }
}

export default useAddressOptions
