import React, {lazy} from 'react'
import useCookiesAccepted from '@hooks/useCookiesAccepted'
import useEffect from '@hooks/useEffect'
import useInitialData from '@page-components/Layout/useInitialData'

export default function FacebookChat() {
  const {integrations: config} = useInitialData()
  const cookies = useCookiesAccepted()

  const getFacebookPageId = () => {
    if (!config) return null
    if (!config.integrations) return null
    if (!config.integrations.facebookChatPageId) return null
    return config.integrations.facebookChatPageId
  }

  useEffect(() => {
    return () => {
      try {
        global.FB.CustomerChat.hide()
      } catch (error) {}
    }
  })

  if (!getFacebookPageId() || !cookies) return null

  const MessengerCustomerChat = lazy(() => import('react-messenger-customer-chat'))

  return (
    <MessengerCustomerChat
      appId="412212256206068"
      language="es_ES"
      version="3.2"
      pageId={getFacebookPageId()}
    />
  )
}
