import React, {ReactNode, Suspense} from 'react'
import isServerSide from '@helpers/misc/isServerSide'

export default function SafeSuspense({
  children,
  fallback
}: {
  children: React.ReactNode
  fallback: ReactNode
}) {
  if (isServerSide()) return <>{children}</>
  return <Suspense fallback={fallback}>{children}</Suspense>
}
