import React, {lazy} from 'react'
import useInitialData from '@page-components/Layout/useInitialData'

export default function HighlightIntegration() {
  const {integrations: config} = useInitialData() as any

  if (!config?.integrations?.highlightId) return null

  const Highlight = lazy(() => import('./Highlight'))

  return <Highlight />
}
