import React from 'react'
import {isContrastGood} from '@helpers/misc/isContrastGood'
import isServerSide from '@helpers/misc/isServerSide'
import useIsDarkMode from '@hooks/useIsDarkMode'
import useLayoutColorTheme from '@hooks/useLayoutColorTheme'
import useInitialData from '@page-components/Layout/useInitialData'

import getColors from './getColors'
import getInputStyles from './getInputStyles'

export default function Styles() {
  const {website, design: config, navbar: navbarColors} = useInitialData()
  const layoutColorTheme = useLayoutColorTheme()
  const isDarkMode = useIsDarkMode()

  if (!website) {
    return null
  }

  const design = {...config?.design}
  const navbar = {...navbarColors?.navbar}

  if (!design.customCss) design.customCss = ''
  if (!design.primaryColor) design.primaryColor = '#2866ff'
  if (!design.backgroundColor) design.backgroundColor = '#ffffff'
  if (layoutColorTheme === 'lightOverwritten') {
    design.backgroundColor = '#ffffff'
    if (!isContrastGood(design.primaryColor, '#ffffff')) design.primaryColor = '#2866ff'
  }

  if (!navbar.backgroundColor) navbar.backgroundColor = '#ffffff'
  if (!navbar.buttonColor) navbar.buttonColor = '#000000'
  if (!navbar.hintColor) navbar.hintColor = '#fdec4f'
  if (!navbar.textColor) navbar.textColor = '#111111'
  if (!navbar.upperBarColor) navbar.upperBarColor = '#ffffff'

  const color = design.primaryColor
  const backgroundColor = design.backgroundColor
  const theCss = design.customCss
  global.isDarkBackground = isDarkMode
  const lighterBackgroundColor =
    layoutColorTheme === 'dark' ? '#222' : layoutColorTheme === 'darkv2' ? '#282F33' : '#fff'
  const descriptionTextColor =
    layoutColorTheme === 'dark' ? '#aaa' : layoutColorTheme === 'darkv2' ? '#aaa' : '#999'
  const textColor =
    layoutColorTheme === 'dark' ? '#fff' : layoutColorTheme === 'darkv2' ? '#fff' : '#111'
  const backgroundBorderColor =
    layoutColorTheme === 'dark' ? '#2b2b2b' : layoutColorTheme === 'darkv2' ? '#373C43' : '#eee'

  const inputBorderColor =
    layoutColorTheme === 'dark' ? '#333' : layoutColorTheme === 'darkv2' ? '#494E62' : '#333'

  // define global website styles as css variables
  if (!isServerSide()) document.documentElement.style.setProperty('--websitePrimaryColor', color)

  const options = {
    backgroundColor,
    textColor,
    lighterBackgroundColor,
    backgroundBorderColor,
    inputBorderColor,
    color,
    isDarkBackground: isDarkMode,
    descriptionTextColor,
    navbar
  }
  const colorCss = getColors(options)
  const selectCss = getInputStyles(options)

  return (
    <>
      {/** The rest of the custom css are attached outside of <Head/> as they are rendered using styled-jsx */}
      {/** Note: The order is important, as the latest one will override any previous one if the css selectors match */}
      {website.fontsCss && website.fontsCss !== '' ? (
        <style jsx global>
          {`
            ${website.fontsCss}
          `}
        </style>
      ) : null}
      {colorCss}
      {selectCss}
      {theCss && theCss !== '' ? (
        <style jsx global>
          {`
            ${theCss}
          `}
        </style>
      ) : null}
    </>
  )
}
