import isServerSide from '@helpers/misc/isServerSide'
import {localStorageGetItem} from '@hooks/useLocalStorageState'
import getQueryParam from '@packages/justo-parts/lib/helpers/getQueryParam'

import generateId from '../misc/generateId'

export default function getDeviceId() {
  if (isServerSide()) return null

  const paramDeviceId = getQueryParam('deviceId')
  if (paramDeviceId) {
    localStorage.setItem('deviceId', paramDeviceId)
    return paramDeviceId
  }

  const savedId = localStorageGetItem('deviceId')
  if (savedId) return savedId

  const generatedId = generateId(50)

  localStorage?.setItem('deviceId', generatedId)
  return generatedId
}
