import React from 'react'
import Zendesk from 'react-zendesk'
import {ZendeskAPI} from 'react-zendesk'
import {initReactPixel} from '@helpers/getReactPixel'
import isScrapping from '@helpers/misc/isScrapping'
import isServerSide from '@helpers/misc/isServerSide'
import useEffect from '@hooks/useEffect'
import useInitialData from '@page-components/Layout/useInitialData'
import {useApolloQuery} from 'apollo-hooks'
import gql from 'graphql-tag'

import loadIntercom from './loadIntercom'
import TawkTo from './TawkTo'

declare const window: {
  Intercom: any
}

export default function UserChat() {
  const {integrations: config} = useInitialData()

  const getIntercomAppId = () => {
    if (isScrapping || isServerSide()) return

    return config?.integrations?.intercomId ?? null
  }

  const getPixelId = () => {
    if (isScrapping || isServerSide()) return

    return config?.integrations?.facebookPixelId ?? null
  }

  const getJustoAdsPixelId = () => {
    if (isScrapping || isServerSide()) return

    return config?.justoAdsPixelId ?? null
  }

  const getAdditionalPixelIds = (): string[] => {
    if (isScrapping || isServerSide()) return

    return config?.additionalPixels ?? []
  }

  const getTawkToId = () => {
    if (isScrapping || isServerSide()) return

    return config?.integrations?.tawkToId ?? null
  }

  const getZendeskId = () => {
    if (isScrapping || isServerSide()) return

    return config?.integrations?.zendeskId ?? null
  }

  const omit = !getTawkToId() && !getPixelId() && !getIntercomAppId() && !getZendeskId()

  const {me} = useApolloQuery({
    query: gql`
      query getUserForChats {
        me {
          _id
          email
          name
          phone
          profile {
            firstName
            lastName
          }
          createdAt
        }
      }
    `,
    fetchPolicy: 'cache-first',
    omit,
    partial: true
  })

  const getIntercomUser = () => {
    const user = me
    if (!user) return
    return {
      user_id: user._id,
      email: user.email,
      // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
      created_at: Math.round(new Date(user.createdAt) * 0.001),
      name: user.name,
      first_name: user.profile.firstName,
      last_name: user.profile.lastName,
      user_hash: user.intercomHash
    }
  }

  const getZendeskUser = () => {
    const user = me
    if (!user) return
    ZendeskAPI('webWidget', 'identify', {name: user.name, email: user.email})
  }

  const getPixelUser = () => {
    const user = me
    if (!user) return
    return {
      em: user.email,
      ph: user.phone ? user.phone.replace('+', '') : null,
      fn: user.profile.firstName,
      ln: user.profile.lastName
    }
  }

  const startIntegrations = async () => {
    if (isServerSide()) return
    if (getIntercomAppId()) {
      await loadIntercom()
      window.Intercom('boot', {
        app_id: getIntercomAppId(),
        ...getIntercomUser()
      })
    }
    if (getZendeskId()) {
      getZendeskUser()
    }
    const pixelId = getPixelId()
    if (pixelId) {
      initReactPixel(pixelId, getPixelUser())
    }
    const justoAdsPixelId = getJustoAdsPixelId()
    if (justoAdsPixelId && justoAdsPixelId !== pixelId) {
      initReactPixel(justoAdsPixelId, getPixelUser())
    }

    const additionalPixelIds = getAdditionalPixelIds()
    if (additionalPixelIds?.length) {
      additionalPixelIds.forEach(pixelId => {
        initReactPixel(pixelId, getPixelUser())
      })
    }
  }

  const restartIntegrations = () => {
    stopIntegrations()
    startIntegrations()
  }

  const stopIntegrations = () => {
    if (getIntercomAppId()) {
      window.Intercom('shutdown')
    }
  }

  useEffect(() => {
    startIntegrations()
    return () => {
      stopIntegrations()
    }
  }, [])

  useEffect(() => {
    restartIntegrations()
  }, [me && me._id])

  return (
    <>
      {getTawkToId() ? <TawkTo id={getTawkToId()} /> : null}
      {getZendeskId() ? <Zendesk zendeskKey={getZendeskId()} /> : null}
    </>
  )
}
