import React from 'react'

import {Address, ClearType, DeliveryTypes, Location, Option, Store} from './models'

export interface AddressesState {
  // This is used to match all the posthog logs that happen on the same session
  sessionId: string

  address?: Address
  addressId?: string
  filter?: string
  options?: Option[]
  selectedCity?: string
  placeId?: string
  location?: Location
  storeId?: string
  preferredDeliveryStoreId?: string

  // derived from the above
  storesInCoverage?: Store[]
  isAvailable?: boolean
  nextAvailableAt?: boolean
  menuId?: string
  websiteIdsInCoverage?: string[]
  isLoadingOptions: boolean
  addressLine2?: string
  deliveryType: DeliveryTypes
  loadingGeolocation: boolean
}

export interface AddressesContext extends AddressesState {
  // initialize: (session: { deviceId?: string, userId?: string })
  setAddressId: (addressId: string) => void
  setFilter: (filter: string) => void
  setSelectedCity: (selectedCity: string) => void
  setPlaceId: (placeId: string) => void
  setLocation: (location: Location) => void
  setStoreId: (storeId: string) => void
  setPreferredDeliveryStoreId: (preferredDeliveryStoreId: string) => void
  setAddressLine2: (addressLine2: string) => void
  createAddress: (params: {addressLine2: string}) => Promise<void>
  setDeliveryType: (deliveryType: DeliveryTypes) => void
  setLoadingGeolocation: (loadingGeolocation: boolean) => void

  clear: (type: ClearType) => void
}

const AddressContext = React.createContext<AddressesContext>({
  sessionId: '',
  deliveryType: DeliveryTypes.DELIVERY,
  isLoadingOptions: false,
  loadingGeolocation: false,
  setAddressId: () => {},
  setFilter: () => {},
  setSelectedCity: () => {},
  setPlaceId: () => {},
  setLocation: () => {},
  setStoreId: () => {},
  setPreferredDeliveryStoreId: () => {},
  setAddressLine2: () => {},
  createAddress: () => new Promise(resolve => resolve()),
  setDeliveryType: () => {},
  setLoadingGeolocation: () => {},

  clear: () => {}
})
AddressContext.displayName = 'AddressContext'

export default AddressContext
