import appOrderPreferences from '@data/fragments/preferences/appOrderPreferences.main'
import storePreferencesFragment from '@data/fragments/preferences/storePreferencesFragment.main'
import gql from 'graphql-tag'

export const getPreferencesQuery = gql`
  query getInitialAddressDataAddressProvider($websiteId: ID) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      placeId
      deliveryType
      address {
        _id
        address
        placeId
        addressSecondary
        addressLine2
        location
      }
      time
      storeId
      store {
        _id
        name
        schedule {
          itsOpenNow: itsAvailableAt
        }
        acceptServeSchedulingOrders
        schedulingOrdersDays
        currentPreparationDuration
        currentExtraDeliveryCost
        currentDeliveryDuration
        isOverloaded
        address {
          placeId
          address
          addressLine2
          location
        }
      }
      preferredDeliveryStoreId
    }
  }
`

export const searchPlacesQuery = gql`
  query searchPlacesAddressProvider(
    $filter: String
    $userId: String
    $websiteId: ID
    $unrestrictedSearch: Boolean
    $countryCodes: [String]
    $sessionTokenGoogleMaps: String
  ) {
    addresses: searchPlace(
      filter: $filter
      userId: $userId
      websiteId: $websiteId
      unrestrictedSearch: $unrestrictedSearch
      countryCodes: $countryCodes
      sessionTokenGoogleMaps: $sessionTokenGoogleMaps
    ) {
      _id
      text
      secondaryText
      location
    }
  }
`

export const setAddressIdMutation = gql`
  mutation setAddressIdPreferencesAddressProvider($websiteId: ID, $addressId: ID) {
    setUserPreferences(websiteId: $websiteId, addressId: $addressId) {
      _id
      placeId
      address {
        _id
        address
        placeId
        addressSecondary
        addressLine2
        location
      }
    }
  }
`

export const setPlaceIdMutation = gql`
  mutation setPlaceIdAddressProvider(
    $websiteId: ID
    $placeId: ID
    $sessionTokenGoogleMaps: String
  ) {
    setPlaceId(
      websiteId: $websiteId
      placeId: $placeId
      sessionTokenGoogleMaps: $sessionTokenGoogleMaps
      shouldThrowPlaceIdError: true
    ) {
      _id
      placeId
      storeId
      store {
        ...storePreferences
      }
      address {
        _id
        address
        placeId
        addressSecondary
        addressLine2
        location
      }
      ...appOrderPreferences
    }
  }

  ${appOrderPreferences}
  ${storePreferencesFragment}
`

export const getStoresInCoverageQuery = gql`
  query getStoresInCoverageAddressProvider($websiteId: ID, $placeId: ID) {
    storesInCoverage(websiteId: $websiteId, placeId: $placeId) {
      _id
      name
      address {
        address
      }
    }
  }
`

export const getGeocodePlaceQuery = gql`
  query getGeocodePlaceAddressProvider($location: JSON) {
    place: searchGeocodePlace(location: $location) {
      _id
      text
      secondaryText
      location
    }
  }
`

export const setPreferredStoreIdMutation = gql`
  mutation setUserPreferredDeliveryStoreIdPreferencesAddressProvider(
    $websiteId: ID
    $preferredDeliveryStoreId: ID
  ) {
    setUserPreferences(websiteId: $websiteId, preferredDeliveryStoreId: $preferredDeliveryStoreId) {
      _id
      store {
        _id
        name
        schedule {
          itsOpenNow: itsAvailableAt
        }
        acceptServeSchedulingOrders
        schedulingOrdersDays
        currentPreparationDuration
        currentExtraDeliveryCost
        currentDeliveryDuration
        isOverloaded
        address {
          address
          placeId
          addressLine2
          location
        }
      }
    }
  }
`

export const setStoreIdMutation = gql`
  mutation setUserStoreIdPreferencesAddressProvider($websiteId: ID, $storeId: ID) {
    setUserPreferences(websiteId: $websiteId, storeId: $storeId) {
      _id
      storeId
      store {
        _id
        name
        schedule {
          itsOpenNow: itsAvailableAt
        }
        acceptServeSchedulingOrders
        schedulingOrdersDays
        currentPreparationDuration
        currentExtraDeliveryCost
        currentDeliveryDuration
        isOverloaded
        address {
          address
          placeId
          addressLine2
          location
        }
      }
    }
  }
`

export const createAddressMutation = gql`
  mutation createAddressAddressProvider(
    $addressLine2: String
    $placeId: ID
    $location: JSON
    $sessionTokenGoogleMaps: String
  ) {
    createAddress(
      addressLine2: $addressLine2
      placeId: $placeId
      location: $location
      sessionTokenGoogleMaps: $sessionTokenGoogleMaps
    ) {
      _id
      location
      address
      placeId
      addressLine2
      comment
    }
  }
`

export const setDeliveryTypeMutation = gql`
  mutation setPreferencesAddressProvider($websiteId: ID, $deliveryType: ID) {
    setUserPreferences(websiteId: $websiteId, deliveryType: $deliveryType) {
      _id
      deliveryType
    }
  }
`

export const updateAddressMutation = gql`
  mutation updateAddressAddressProvider(
    $addressId: ID
    $address: UpdateAddressInput
    $websiteId: ID
  ) {
    result: updateAddress(addressId: $addressId, address: $address, websiteId: $websiteId) {
      _id
      deletedAt
      createdAt
      userId
      deviceId
      placeId
      address
      addressLine2
      acceptsNoLine2
      placeId
      addressSecondary
      location
      comment
    }
  }
`

export const getWebsiteStoresQuery = gql`
  query getWebsiteStoresAddressProvider($websiteId: ID) {
    stores(websiteId: $websiteId) {
      _id
      items {
        _id
        label: name
        value: _id
        acceptServe
        acceptGo
        schedulingOrdersDays
        address {
          placeId
          location
        }
      }
    }
  }
`

export const getMyOrderPreferencesQueryAddressProvider = gql`
  query getMyOrderPreferencesAddressProvider($websiteId: ID) {
    preferences: userPreferences(websiteId: $websiteId) {
      _id
      time
      storeId
      placeId
      websiteId
      store {
        _id
        name
        schedule {
          itsOpenNow: itsAvailableAt
        }
        acceptServeSchedulingOrders
        schedulingOrdersDays
        currentPreparationDuration
        currentExtraDeliveryCost
        currentDeliveryDuration
        isOverloaded
        address {
          placeId
          address
          addressLine2
          location
        }
      }
      tableName
      preferredDeliveryStoreId
    }
  }
`
